import { FacebookProvider, SendToMessenger } from "react-facebook";
import Image from "next/image";
import messenger from "../../assets/img/messenger.png";

interface IFacebookMessengerPanelProps {
    movementType: string;
    flightNumber: string;
    originalFlightDate: string;
}

const FacebookMessenger: React.FC<IFacebookMessengerPanelProps> = ({ movementType, flightNumber, originalFlightDate }: IFacebookMessengerPanelProps) => {

    //NOTA: D_EC_03545_2023-06-29 TIPO_VETTORE_FLIGHTNUMBER_DATAVOLO
    function concatString() {
        return "SUBSCRIBE_FLIGHT=" + movementType + '_' + flightNumber.replace(' ', '_') + '_' + originalFlightDate;
    }

    return (
        <>
            <div className='flex flex-col flex-1 items-center'>
                
                <Image alt='' src={messenger} className="w-8 h-8"/>
                <p className='text-black text-sm mt-2 font-normal'>Messenger</p>

                <div className="absolute w-24 h-16 overflow-hidden translate-y-3" style={{opacity:0}}>
                    <FacebookProvider appId="520733428100479">
                        <SendToMessenger
                            messengerAppId="479207302273582"
                            pageId="328409017245930"
                            dataRef={concatString()}
                            ctaText="SEND_ME_UPDATES"
                            color={undefined}
                            size="standard"
                        />
                    </FacebookProvider>
                </div>
                   
            </div>
        </>
	);
}

export default FacebookMessenger
