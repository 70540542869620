import { useIsMobile } from "@/hooks";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const availableLanguage = [{ id: 1, label: 'IT' }, { id: 2, label: 'EN' }];

interface ILanguageSelectorProps {
    hostPageData: any
}

const LanguageSelector: React.FC<ILanguageSelectorProps> = ({ hostPageData }: ILanguageSelectorProps) => {

    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [isMobile] = useIsMobile()


    const changeLang = ((langItem: number) => {
        let newLang = availableLanguage.find(item => item.id === langItem)?.label.toLowerCase();
        if (!newLang) return console.error('The choosen language is not available')
        i18n.changeLanguage(newLang as string)
    })

    return (
        <div className="flex gap-1">
            {availableLanguage.map(languageItem => { return <LanguageItem hostPageData={hostPageData} key={languageItem.id} isMobile={isMobile} languageItem={languageItem} currentLang={lang} onClick={(itemId: number) => changeLang(itemId)} /> })}
        </div>
    )
}

const LanguageItem = ({ hostPageData, languageItem, onClick, currentLang, isMobile }: { hostPageData: any, languageItem: { id: number, label: string }, onClick: ((itemId: number) => any) | ((itemId: number) => void), isMobile: boolean, currentLang: string }) => {
    const {query} = useRouter()
    const [link, setLink] = useState<string>('')
    const getCurrentPath = () => {
        let currentPath = window.location.href;
        currentPath.toLowerCase();
        let newPath = currentPath;
        newPath = newPath.replace("/" + currentLang.toLowerCase() + "/", "/" + languageItem.label.toLowerCase() + "/");
        return newPath
    };


    useEffect(() => {
        let resultUrl = hostPageData ? hostPageData?.lblUrlPath[languageItem.label.toLowerCase()] : getCurrentPath();

        // console.log('Query: ', query);

        if(hostPageData && Object.keys(query).filter(key => key !== 'lang' && key !== 'paths').length) resultUrl += ("?"+Object.keys(query).filter(key => key !== 'lang' && key !== 'paths').map(key => {
            return key + "=" + query[key]
        }).join("&"))
        setLink(resultUrl);
    }, [hostPageData, currentLang, globalThis.window?.location?.href]);

    return (
        <a href={link}>
            <div className={`p-3 z-50 w-12 flex justify-center cursor-pointer rounded-2xl transition duration-300 ${languageItem.label.toLowerCase() === currentLang ? 'bg-lightgrey' : 'bg-white hover:bg-lightblue hover:text-white'} ${isMobile ? 'shadow-lg' : ''}`} onClick={() => { }} key={languageItem.id}>
                {languageItem.label}
            </div>
        </a>

    )
}

export default LanguageSelector;