import { useRouter } from 'next/router'
import { IFirstLevelNavItem } from '@/interfaces/INavigationDTO';
import { SideMenuAtom } from '@/atoms';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hoveredElementIdAtom } from '@/atoms/NavigationAtoms';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import CustomIcon from '../CustomIcon/CustomIcon';
import LaunchCard from '../Card/LaunchCard';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import getPathByLink from "@/utils/getPathByLink";
import cabledData from "@/utils/cabledData";
import { useIsMobile } from '@/hooks';

interface MobileSideMenuProps {
  menuItems: IFirstLevelNavItem[];
  categoryData: any;
  imageUrl: { path: string, description: string, alt: string };
  hostPageData?: any;
  language: string;
}

const MobileSideMenu: React.FC<MobileSideMenuProps> = ({ hostPageData = null, categoryData, menuItems, imageUrl, language }: MobileSideMenuProps) => {

  const [isMenuOpen, setIsMenuOpen] = useRecoilState(SideMenuAtom);
  const [isMobile] = useIsMobile();
  const [selectedFirstLevelItem, setSelectedFirstLevelItem] = useState<number | null>(null);
  const { i18n } = useTranslation();
  const lang = language? language : i18n.language;
  const router = useRouter();
  const setHoveredElementId = useSetRecoilState(hoveredElementIdAtom);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setSelectedFirstLevelItem(null);
  };

  const handleFirstLevelItemClick = (id: number) => {
    setSelectedFirstLevelItem(id === selectedFirstLevelItem ? null : id);
  };

  const bannerNavigation = useMemo(() => {
    let selectedItem = menuItems.find(item => item.metainfo.id === selectedFirstLevelItem);
    if (!selectedItem) return null
    if (!selectedItem?.ti) return null;

    let imageEl = selectedItem.ti.find(item => item.grpTypeMenu === 'grpSecondLevelBanner');
    let imageAddr = 
      isMobile ? 
        imageEl?.upiImageMobile?.mediaserverpath && imageEl?.upiImageMobile?.resourcename ? imageEl.upiImageMobile.mediaserverpath + "/" + imageEl.upiImageMobile.resourcename : '' : 
        imageEl?.upiImageDesktop?.mediaserverpath && imageEl?.upiImageDesktop?.resourcename ? imageEl.upiImageDesktop.mediaserverpath + "/" + imageEl.upiImageDesktop.resourcename : '';
    
    if (imageAddr != '' && imageEl?.labelMultilanguage[lang] != "" && imageEl?.labelMultilanguage[lang] != " ") return (
      <LaunchCard txtColor={imageEl?.enuBackgroundLabel === "bianco" ? 'text-white' : ''} isLaunch={true} textSize="sm" imageUrl={imageAddr} title={imageEl?.labelMultilanguage[lang] || ''} onClick={() => router.push(imageEl?.lblLink[lang] || '/')} />
    )
  }, [menuItems, selectedFirstLevelItem, lang])
  
  const mainMenu = useMemo(() => {
    
    return menuItems.map((menuItem, index) => (
      <React.Fragment key={menuItem.metainfo.id}>
        <div className='px-8'>
          <li className={`${selectedFirstLevelItem !== null && selectedFirstLevelItem !== menuItem.metainfo.id ? 'hidden' : ''}`}>
            <button
              className={`flex ${selectedFirstLevelItem !== null ? 'flex-row-reverse justify-end' : ''} items-center gap-4 w-full py-2 focus:outline-none`}
              onClick={() => handleFirstLevelItemClick(menuItem.metainfo.id)}
            >
              <span className='font-semibold text-3xl'>{menuItem.labelMultilanguage[lang]}</span>
                <CustomIcon
                  color=""
                  iconName="arrow"
                  className={`${selectedFirstLevelItem !== null ? 'rotate-180 m-0' : ''}  ml-2 h-3 w-3`}
                />
            </button>
          </li>
          {selectedFirstLevelItem === menuItem.metainfo.id && menuItem.ti && (
            <ul className=" mt-2">
              {menuItem.ti.filter(item => item.grpTypeMenu === "grpSecondLevelDirect").map((subItem, secondLevelIndex, thisArr) => (
                <li key={subItem.metainfo.id} className={`${secondLevelIndex !== (thisArr.length - 1) ? 'border-b-2 border-lightgrey' : ''} pb-2`}>
                  <a
                    className="flex items-center w-full py-2 focus:outline-none"
                    onClick={() => {
                      setIsMenuOpen(false);
                      setSelectedFirstLevelItem(null);

                      // handle sub item click here
                      // navigateTo(subItem?.lblLink[lang] || '')
                    }}
                    href={getPathByLink(subItem, lang)}
                  >
                    <span className="font-bold">{subItem.labelMultilanguage[lang]}</span>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
        {selectedFirstLevelItem === menuItem.metainfo.id && menuItem.ti ? (
          <ul className="mt-6">
            { menuItem.label === "Categorie" ?
              <div className='px-8'>
                <div className='mb-8'>
                  {categoryData?.map((item: any, index: number) => { 
                    return ( 
                        <a onClick={() => setHoveredElementId(null)} target='_blank' key={index} href={process.env.NEXT_PUBLIC_SITE_BOUTIQUE + item.custom_url.url}>
                            <div className="cursor-pointer pb-3 mb-3 mt-3 border-b-2 border-lightgrey font-bold">{item.name}</div>
                        </a> 
                    )
                  })}
                </div>
              </div>
              : null
            }
            {menuItem.ti.filter((item) => item.grpTypeMenu === "grpSecondLevelContainer").map((subItem, thirdLevelIndex, thisArr) => (
              <div key={subItem.metainfo.id} className='thirdLevelWrapper'>
                <div className={`${thirdLevelIndex !== (thisArr.length - 1) ? 'border-b-2 border-lightgrey' : ''}  py-4 px-8 bg-lightgrey`}>
                  <span className="text-mediumgrey">{subItem.labelMultilanguage[lang]}</span>
                </div>
                {subItem.ti && subItem.ti.map((thirdLevelItem, currentIndex) => {
                  return (
                    <a onClick={() => setHoveredElementId(null)} href={getPathByLink(thirdLevelItem, lang)} key={thirdLevelItem.metainfo.id}>
                      <div className='py-4 px-8'>
                        <div className='mb-4'>
                          {thirdLevelItem.labelMultilanguage[lang]}
                        </div>
                        {currentIndex !== (subItem.ti.length - 1) ? <hr /> : null}
                      </div>
                    </a>
                  )
                })}
              </div>
            ))}
          </ul>
        ) :
          <></>
        }
        
      </React.Fragment>
    ))
  }, [selectedFirstLevelItem, lang])

  return (
    <div
      className={`text-black fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-50 transform duration-500 ease-in-out ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      <div className="relative overflow-y-auto h-full max-w-full w-full bg-white shadow-lg">
        <>
          <div className="flex border-lightgrey border-b-2 py-4 px-3">
            <div className='logoContainer ml-auto w-6/12'>
              <img src={imageUrl.path} />
            </div>
            <div className='w-3/12 flex justify-end'>
              <button className="text-gray-600 focus:outline-none" onClick={toggleMenu}>
                <CustomIcon iconName="close" />
              </button>
            </div>
          </div>
          <ul className="pt-6">
            {mainMenu}
            {selectedFirstLevelItem !== null ? <div className='aspect-[320/120] flex-1 mx-8 my-4 pb-'>
              {bannerNavigation}
            </div> : null}
          </ul>
          {!selectedFirstLevelItem && selectedFirstLevelItem !== 0 ? <div className='absolute bottom-4 left-8'>
            <LanguageSelector hostPageData={hostPageData} />
          </div> : null}
        </>
      </div>
    </div>
  );
};

export default MobileSideMenu;
