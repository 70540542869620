import { useRouter } from "next/router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IFirstLevelNavItem } from "@/interfaces/INavigationDTO";
import { hoveredElementIdAtom } from "@/atoms/NavigationAtoms";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LaunchCard from "../Card/LaunchCard";
import getPathByLink from "@/utils/getPathByLink";
import { configColor } from "@/utils";
import cabledData from "@/utils/cabledData";

interface IMegaMenuProps {
    navItems: IFirstLevelNavItem[],
    language: string,
    categoryData: any
}

const MegaMenu: React.FC<IMegaMenuProps> = ({ navItems, language, categoryData }: IMegaMenuProps) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;

    const setHoveredElementId = useSetRecoilState(hoveredElementIdAtom)
    const selectedElementId = useRecoilValue(hoveredElementIdAtom);
    const [sideNavHeight, setSideNavHeight] = useState<number>(388)
    const router = useRouter();
    let selectedItem = navItems.find(item => item.metainfo.id === selectedElementId)

    const sideNavItems = useMemo(() => {

        if (selectedItem?.label == 'Categorie') {

            return categoryData?.map((item: any, index: number) => { 
                return ( 
                    <a onClick={() => setHoveredElementId(null)} target='_blank' key={index} href={process.env.NEXT_PUBLIC_SITE_BOUTIQUE + item.custom_url.url}>
                        <div className="cursor-pointer font-bold mb-2 pr-8">{item.name}</div>
                    </a> 
                )
            })
        }
        if (!selectedItem) return null;
        if (!selectedItem?.ti) return null;
        
        return selectedItem.ti.filter(item => item.grpTypeMenu === "grpSecondLevelDirect").map(item => { return <a onClick={() => setHoveredElementId(null)} key={item.metainfo.id} href={getPathByLink(item, lang)}><div className="cursor-pointer font-bold">{item.labelMultilanguage[lang]}</div></a> })
    }, [navItems, selectedElementId, lang, categoryData])

    const containerNav = useMemo(() => {
        if (!selectedItem) return null;
        if (!selectedItem?.ti) return null;

        return selectedItem.ti.filter(item => item.grpTypeMenu === 'grpSecondLevelContainer').map(item => {
            let result = (<div key={item.metainfo.id} className="flex flex-col gap-4">
                <div className="colTitle text-mediumgrey">{item.labelMultilanguage[lang]}</div>
                {item?.ti ? item?.ti.map((containerNavItem: any) => {
                    return <a onClick={() => setHoveredElementId(null)} key={containerNavItem.metainfo.id} href={getPathByLink(containerNavItem, lang)}><div>{containerNavItem.labelMultilanguage[lang]}</div></a>
                }) : []}
            </div>)
            return result
        })
    }, [navItems, selectedElementId, lang])

    

    const bannerNavigation = useMemo(() => {
        if (!selectedItem) return null;
        if (!selectedItem?.ti) return null;

        let imageEl = selectedItem.ti.find(item => item.grpTypeMenu === 'grpSecondLevelBanner');
        if (!imageEl) return null
        let imageAddr = imageEl?.upiImageDesktop?.mediaserverpath && imageEl?.upiImageDesktop?.resourcename ? imageEl.upiImageDesktop.mediaserverpath + "/" + imageEl.upiImageDesktop.resourcename : '';

        return (
            getPathByLink(imageEl, lang) ?
                <a href={getPathByLink(imageEl, lang)} target={imageEl?.flgTarget ? "_blank" : "_self"}>
                    <LaunchCard isMenu txtColor={configColor("text", imageEl.enuBackgroundLabel)} isLaunch={true} textSize="sm" imageUrl={imageAddr} title={imageEl?.labelMultilanguage[lang] || ''} onClick={() => { }} isSquare={true} />
                </a>
            : <></>
        )
    }, [navItems, selectedElementId, lang])

    /*     useEffect(() => setSideNavHeight(document.getElementById('sideNav')?.offsetHeight || 0), [selectedElementId])
     */
    return (
        <div style={{ transition: 'height .6s', height: selectedElementId && sideNavHeight && selectedElementId !== 999 ? sideNavHeight + 32 : 0 }} className={`text-black flex ${selectedElementId && sideNavHeight && selectedElementId !== 999 ? 'py-4 px-10' : ''} justify-between overflow-hidden absolute w-full bg-white z-50 `}>

            {sideNavItems && sideNavItems?.length ?
                (selectedItem?.label == 'Categorie') ?
                <div id="sideNav" className="grid grid-rows-6 grid-flow-col gap-5 w-auto min-w-[285px] max-h-full h-fit border-r-2 border-r-lightgrey">
                    {sideNavItems || []}
                </div>
                :   
                <div id="sideNav" className="flex flex-col gap-5 w-auto min-w-[285px] max-h-full h-fit border-r-2 border-r-lightgrey">
                    {sideNavItems || []}
                </div>
                : null}

            {/* <div className={(!(sideNavItems && sideNavItems?.length) ? "w-8/12" : " w-6/12") + " flex gap-4"}> */}
            <div className={"flex justify-start flex-auto px-8 gap-14"}>
                {containerNav || []}
            </div>


            {bannerNavigation ?
                <div className=" aspect-square h-60 overflow-hidden">
                    {bannerNavigation}
                </div>
                : null}

        </div>
    )
}

export default MegaMenu;