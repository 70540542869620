import { useCallback, useEffect, useState } from "react";
import { configColor, getPathByLink } from "@/utils";
import { useTranslation } from "react-i18next";

import Button from "../Button/Button";
import DOMPurify from "isomorphic-dompurify";
import CustomPicker from "../CustomPicker/CustomPicker";
import CustomTimePicker from "../CustomTimePicker/CustomTimePicker";
import React from "react";

interface LaunchBoxPanelProps {
    data: any,
    language: string
}

const LaunchBoxPanel: React.FC<LaunchBoxPanelProps> = ({ data, language }) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const lblTitleBoxLaunch: string = DOMPurify.sanitize(data?.lblTitleBoxLaunch[lang]);
    const txbSubtitleBoxLaunch: string = DOMPurify.sanitize(data?.txbSubtitleBoxLaunch[lang]);
    const enuTitleColorLaunchBox = configColor('text', data?.enuTitleColorLaunchBox)
    const flgPageVip = data?.flgPageVip;
    const flgTargetBoxLaunch = data?.flgTargetBoxLaunch ? "_blank" : "_self"
    const todayDate = new Date;
    const todayTime = new Date;
    const [date, setDate] = useState<Date>();
    const [time, setTime] = useState<Date>();
    const [formattedDate, setFormattedDate] = useState<string>((todayDate?.getDate() < 10 ? "0"+todayDate?.getDate() : todayDate?.getDate()) + "%2F" + (todayDate?.getMonth()! + 1 < 10 ? "0"+todayDate?.getMonth()! + 1 : todayDate?.getMonth()! + 1) + "%2F" + todayDate?.getFullYear());
    const [formattedTime, setFormattedTime] = useState<string>((todayTime.getHours() < 10 ? "0"+todayTime.getHours() : todayTime.getHours() )+"%3A"+(todayTime.getMinutes() < 10 ? "0"+todayTime.getMinutes() : todayTime.getMinutes()));

    const maxTime = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        return date;
    }

    const minTime = () => {
        const date = new Date();
        date.setHours(date.getMinutes() < 30 ? date.getHours() + 3 : date.getHours() + 4);
        date.setMinutes(date.getMinutes() === 30 ? 30 : 0);
        return date;
    }

    useEffect(() => {

        if(date) 
            setFormattedDate((date?.getDate() < 10 ? "0"+date?.getDate() : date?.getDate()) + "%2F" + (date?.getMonth()! + 1 < 10 ? "0"+date?.getMonth()! + 1 : date?.getMonth()! + 1) + "%2F" + date?.getFullYear())
    
    },[date])
    

    useEffect(() => {

        if(time) 
            setFormattedTime((time.getHours() < 10 ? "0"+time.getHours() : time.getHours() )+"%3A"+(time.getMinutes() < 10 ? "0"+time.getMinutes() : time.getMinutes()))
        
    },[time]);

    return <>
        <div className="bg-lightgrey flex w-full rounded-2xl px-3 py-5 desk:p-10 flex-col items-start gap-4 desk:gap-0 desk:flex-row desk:items-center justify-between">

            <div className={`${enuTitleColorLaunchBox} flex flex-col flex-1 desk:pr-6`}>
                <h2 className="font-bold" dangerouslySetInnerHTML={{ __html: lblTitleBoxLaunch }} />
                <h5 className="leading-[22px]" dangerouslySetInnerHTML={{ __html: txbSubtitleBoxLaunch }} />
            </div>

            {flgPageVip ?
                <React.Fragment>
                    <div className="customPickerWrapper flex w-full desk:px-6 h-[54px] desk:w-fit desk:flex desk:flex-1 relative items-center gap-1 aspect-[281/54]">
                        <CustomPicker minDate={new Date(+180)} selected={date ? date : new Date()} popperPlacement={"bottom"} onChange={(newDate: Date) => setDate(newDate)} />
                        <CustomTimePicker minTime={minTime()} maxTime={maxTime()} timeIntervals={30} selected={time ? time : minTime()} onChange={(newTime: Date) => setTime(newTime)} />
                    </div>
                    <a href={`https://www.milanairports-shop.com/${lang}/search/vip-lounge?product=VL&airport=${process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE?.toLocaleUpperCase()}&checkInDate=${formattedDate}&checkInTime=${formattedTime}&accountCode=`} target={flgTargetBoxLaunch}>
                        <Button text={`${data?.lblLabelCtaBoxLaunch[lang]}`} />
                    </a>
                </React.Fragment>
                : 
                <a href={data?.lblLinkBoxLaunch[lang]} target={flgTargetBoxLaunch}>
                    <Button text={`${data?.lblLabelCtaBoxLaunch[lang]}`} />
                </a>}
                
        </div>
    </>
}

export default LaunchBoxPanel