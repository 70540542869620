import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
import { useSetRecoilState } from "recoil";
import { hoveredElementIdAtom } from "../../atoms/NavigationAtoms";
import BoxNoFidelityCard from "../BoxNoFidelityCard/BoxNoFidelityCard";
import { MoonLoader } from "react-spinners";
import { useAuth } from "@/context/AuthContext";

interface IAccountDialogProps {
  userInfo: any;
}

const AccountDialog: React.FC<IAccountDialogProps> = ({
  userInfo,
}: IAccountDialogProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [name, setName] = useState("");
  const [points, setPoints] = useState(0);
  const setHoveredElementId = useSetRecoilState(hoveredElementIdAtom);
  const [hasMvp, setHasMvp] = useState<boolean>(false);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function getUserData() {
    if (userInfo.token && process.env.NEXT_PUBLIC_AUTH_KEYID) {
      setIsLoading(true);

      try {
        const apiUserResult = await fetch(
          `${process.env.NEXT_PUBLIC_AUTH}/authenticated-user`,
          {
            method: "GET",
            headers: new Headers({
              Authorization: userInfo.token,
              "Content-Type": "application/json",
              keyId: process.env.NEXT_PUBLIC_AUTH_KEYID,
            }),
          }
        );

        const data = await apiUserResult?.json();

        setName(data.firstName);
        setPoints(data.points);
        setHasMvp(data.checkVMP);
        setUserId(data.userId);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const fetchCard = () => {

    fetch(`${process.env.NEXT_PUBLIC_CARD_PDF_PATH}/${lang}/pdf-card?userId=${userId}&dl=1`, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'keyId': process.env.NEXT_PUBLIC_CARD_PDF_KEY!
        }),
    })
    .then(response => {

        return response.blob();
    })
    .then(response => {

        const pdf = new Blob([response], { type: 'application/pdf' });
        window.open(URL.createObjectURL(pdf))
    })
    .catch(error => console.error(error));
    
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="absolute flex -right-[132px] top-[25px] h-[331px] shadow-2xl">
      <>
        <div className="bg-white w-[267px] pt-10 pl-10 pr-5">
          {isLoading ? (
              <MoonLoader className="m-auto mt-16" color="#6ABACE" />
          ) : (
            <>
              {" "}
              <div className="text-2xl font-semibold font-primary">
                {t("account.hello")} {name}!
              </div>
              <div className="mt-4">
                <ul className="flex flex-col gap-2 text-lg">
                  {hasMvp && (
                    <a
                      href={
                        userInfo.codTessera
                          ? `/${lang}/profile/fidelitycard`
                          : ""
                      }
                      onClick={() => setHoveredElementId(null)}
                    >
                      <li className="cursor-pointer hover:underline hover:text-[lightblue]">
                        {t("account.fidelityCard")}
                      </li>
                    </a>
                  )}
                  <a
                    href={`/${lang}/profile/flighttracking`}
                    onClick={() => setHoveredElementId(null)}
                  >
                    <li className="cursor-pointer hover:underline hover:text-[lightblue]">
                      {t("account.flightsTracking")}
                    </li>
                  </a>

                  <a
                    href={`/${lang}/profile/purchase`}
                    onClick={() => setHoveredElementId(null)}
                  >
                    <li className="cursor-pointer hover:underline hover:text-[lightblue]">
                      {t("account.myPurchases")}
                    </li>
                  </a>

                  <a
                    href={`/${lang}/profile/account`}
                    onClick={() => setHoveredElementId(null)}
                  >
                    <li className="cursor-pointer hover:underline hover:text-[lightblue]">
                      {t("account.myAccount")}
                    </li>
                  </a>
                </ul>
              </div>
            </>
          )}
        </div>

        
          {isLoading ? null : (
            <>
              {hasMvp ? (
                <div className="bg-lightgrey w-[254px] pt-10 pl-5 pb-11 font-primary flex flex-col justify-between">
                  <div className="pr-10">
                    <div className="text-2xl font-semibold">
                      {t("account.points1")} {points} {t("account.points2")}
                    </div>
                    <div className="mt-6">
                      {t("account.fidelityCardNumber")}
                      <br />
                      {userInfo.codTessera}
                    </div>
                  </div>
                  <a
                    href={
                      userInfo.codTessera ? `/${lang}/profile/fidelitycard` : ""
                    }
                    onClick={() => setHoveredElementId(null)}
                  >
                    <div onClick={() => fetchCard()} className="p-4 border-2 border-black rounded-full mr-4 flex gap-[10px] w-[219px] h-[62px] items-center">
                      <div className="w-[40px] h-[26px]">
                        <CustomIcon iconName="qrcode-account" color="black" />
                      </div>
                      <div className="uppercase font-bold leading-[1] text-sm">
                        {userInfo?.codTessera ? (
                          <>{t("account.showFidelityCard")}</>
                        ) : (
                          <>{t("account.getYourFidelityCard")}</>
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
      </>
    </div>
  );
};

export default AccountDialog;
